@import url("https://fonts.googleapis.com/css2?family=Fira+Code:wght@300..700&family=Open+Sans:ital,wght@0,300..800;1,300..800&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    body {
        @apply h-screen bg-gray-100 font-gtWalsheim;
    }
}

@font-face {
    font-family: "regular";
    src: url("./fonts/GT-Walsheim-Regular-Trial.otf") format("opentype");
}
